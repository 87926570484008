import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import './index.css';
import "./assets/css/bootstrap/main.css";
import "react-toastify/dist/ReactToastify.css";
//import 'bootstrap/dist/css/bootstrap.rtl.min.css';
const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <Suspense
    fallback={
      <div className="d-flex align-items-center justify-content-center bg-secondary vh-100 text-white-50">
        <div className="spinner-border" role="status" />
      </div>
    }
  >
    <App />
  </Suspense>,
  document.getElementById("root")
);
